// deps
import React, {
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { css, StyleSheet } from "aphrodite";

// utils
import functionNoop from "@cloudspire/shared/utils/function/noop";

const initialOnChangeSlide = functionNoop;

const styles = StyleSheet.create({
  carousel: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  pages: {
    display: "flex",
    height: "100%",
  },
  page: {
    height: "100v",
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "100%",
  },
  navigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  leftNavigation: {
    left: 0,
  },
  rightNavigation: {
    right: 0,
  },
});

type Props = {
  shouldAutoplay?: boolean;
  autoplayOptions?: { delay: number };
  animationDuration?: string;
  rightNavigation?: React.ReactElement;
  leftNavigation?: React.ReactElement;
  style?: object;
  onChangeSlide?: (params: { index: number }) => void;
  children: React.ReactNode;
};

const Carousel = forwardRef(function Carousel(props: Props, ref) {
  const {
    leftNavigation,
    rightNavigation,
    animationDuration = 320,
    shouldAutoplay = true,
    autoplayOptions = {} as Props["autoplayOptions"],
    onChangeSlide = initialOnChangeSlide,
  } = props;

  const { delay = 3000 } = autoplayOptions;

  const childrenArray = React.Children.toArray(props.children);

  const pages =
    0 < childrenArray.length
      ? [
          childrenArray[childrenArray.length - 1],
          ...childrenArray,
          childrenArray[0],
        ]
      : [];

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const interval = useRef(null);

  const handleChangeSlide = useCallback(
    function (index) {
      setIsTransitioning(true);
      setCurrentPageIndex(index);
      onChangeSlide({ index: index - 1 });
    },
    [onChangeSlide]
  );

  /**
   * Gère le changement vers la slide suivante
   */
  const handleNext = useCallback(
    function () {
      handleChangeSlide(currentPageIndex + 1);
    },
    [currentPageIndex, handleChangeSlide]
  );

  /**
   * Gère le changement vers la slide précédente
   */
  const handlePrevious = useCallback(
    function () {
      handleChangeSlide(currentPageIndex - 1);
    },
    [currentPageIndex, handleChangeSlide]
  );

  /**
   * Démarre l'autoplay
   */
  const startAutoplay = useCallback(
    function () {
      if (shouldAutoplay) {
        interval.current = setInterval(handleNext, delay);
      }
    },
    [shouldAutoplay, handleNext, delay]
  );

  /**
   * Arrête l'autoplay
   */
  function stopAutoplay() {
    clearInterval(interval.current);
  }

  /**
   * Gère le clic sur le bouton précédent
   */
  function handleClickPrevious() {
    stopAutoplay();
    startAutoplay();
    handlePrevious();
  }

  /**
   * Gère le clic sur le bouton suivant
   */
  function handleClickNext() {
    stopAutoplay();
    startAutoplay();
    handleNext();
  }

  useEffect(
    function () {
      startAutoplay();
      return stopAutoplay;
    },
    [startAutoplay]
  );

  useEffect(() => {
    if (currentPageIndex === 0) {
      setTimeout(function () {
        setIsTransitioning(false);
        setCurrentPageIndex(pages.length - 2);
      }, animationDuration);
    }
    if (currentPageIndex === pages.length - 1) {
      setTimeout(function () {
        setIsTransitioning(false);
        setCurrentPageIndex(1);
      }, animationDuration);
    }
  }, [currentPageIndex, animationDuration, pages.length]);

  useImperativeHandle(ref, () => ({
    setPage({ newActiveIndex }) {
      handleChangeSlide(newActiveIndex + 1);
    },
  }));

  const dynamicStyles = StyleSheet.create({
    pages: {
      transform: `translateX(-${currentPageIndex * 100}%)`,
      transition: isTransitioning ? `transform ${animationDuration}ms` : "none",
    },
  });

  return (
    <div className={css(styles.carousel)}>
      <div className={css(styles.pages, dynamicStyles.pages)}>
        {pages.map((page, pageIndex) => (
          <div key={pageIndex} className={css(styles.page)}>
            {cloneElement(page, {
              style: {
                width: "100%",
                height: "100%",
              },
            })}
          </div>
        ))}
      </div>
      {childrenArray.length > 1 && (
        <>
          {leftNavigation && (
            <div
              className={css(styles.navigation, styles.leftNavigation)}
              onClick={handleClickPrevious}
            >
              {leftNavigation}
            </div>
          )}
          {rightNavigation && (
            <div
              className={css(styles.navigation, styles.rightNavigation)}
              onClick={handleClickNext}
            >
              {rightNavigation}
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default Carousel;
