// dependencies
import React, { Fragment } from "react";
import { View } from "react-native";
import { StyleSheet, css } from "aphrodite";

// components
import BaseComponent from "@cloudspire/legacy-resources/src/components/BaseComponent2";
import Stack from "@cloudspire/legacy-resources/src/components/Stack";
import Text from "@cloudspire/legacy-resources/src/components/Text2";
import FlexEmbed from "@cloudspire/legacy-resources/src/components/FlexEmbed2";
import RoomBookableOnlineTag from "@cloudspire/legacy-resources/src/components/RoomBookableOnlineTag";
import RoomTrophy from "@cloudspire/legacy-resources/src/components/RoomTrophy";
import Carousel from "@cloudspire/legacy-resources/src/components/Carousel";
import Image from "@cloudspire/legacy-resources/src/components/Image";
import Link from "@cloudspire/legacy-resources/src/components/Link2";
import RoomRankings from "@cloudspire/legacy-resources/src/components/RoomRankings";
import RoomNewTag from "@cloudspire/legacy-resources/src/components/RoomNewTag";
import RoomRateAndReviews from "@cloudspire/legacy-resources/src/components/RoomRateAndReviews";
import RoomMetaList from "@cloudspire/legacy-resources/src/components/RoomMetaList";
import { Row, Col } from "@cloudspire/legacy-resources/src/components/Grid2";
import RoomHeader from "./Header";
import CarouselLeftNavigation from "./CarouselLeftNavigation";
import CarouselRightNavigation from "./CarouselRightNavigation";
import RoomRankingStar from "../RoomRankings/Star";

// helpers
import {
  roomHasRanking,
  roomHasReviews,
} from "@cloudspire/legacy-resources/src/helpers/room";
import roomGetTrophyList from "../../helpers/room/getTrophyList";

// constants
import { HOTEL_RATING_TYPE_VALUE_STAR } from "@cloudspire/shared/constants/hotel/rating";

const styles = StyleSheet.create({
  inline: {},
  row: {
    width: "100%",
  },
  media: {},
  bookableOnline: {},
  awards: {},
  roomMetaList: {
    fontSize: "0.75rem",
  },
  feedback: {},
  about: {
    fontSize: "0.875rem",
  },
});

type PropsType = {
  flux: "block" | "block-compact";
  to: string;
  room: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/room").roomParseData
  >;
  linkProps?: any;
  context?: "home" | "search" | "campaign" | "lastSeen";
  onPress?;
};

const Inline: React.FunctionComponent<PropsType> = (props) => {
  const { to, room, flux, context, linkProps, onPress } = props;

  const ratio = 3 / 4;

  const roomTrophyList = roomGetTrophyList({ room });

  return (
    <BaseComponent aStyle={styles.inline}>
      <Row type="cell" maxColumns={4} gutter={0.5} aStyle={styles.row}>
        <Col>
          <div className={css(styles.media)}>
            <FlexEmbed ratio={ratio}>
              <Carousel
                shouldAutoplay={false}
                leftNavigation={<CarouselLeftNavigation />}
                rightNavigation={<CarouselRightNavigation />}
              >
                {room.pictureList.map((media, index): JSX.Element => {
                  return (
                    <View key={index}>
                      {to ? (
                        <Link
                          {...linkProps}
                          target="_blank"
                          to={to}
                          key={index}
                        >
                          <View style={{ width: "100%" }}>
                            <Image
                              resizeMode="cover"
                              ratio={ratio}
                              source={{ uri: media.urls.small }}
                              accessibilityLabel={media.caption ?? room?.name}
                            />
                          </View>
                        </Link>
                      ) : (
                        <View style={{ width: "100%" }}>
                          <Image
                            resizeMode="cover"
                            ratio={ratio}
                            source={{ uri: media.urls.small }}
                            accessibilityLabel={media.caption ?? room?.name}
                          />
                        </View>
                      )}
                    </View>
                  );
                })}
              </Carousel>
            </FlexEmbed>

            {flux === "block" && room.isBookableOnline && (
              <div className={css(styles.bookableOnline)}>
                <RoomBookableOnlineTag />
              </div>
            )}

            {"block" === flux && (
              <Fragment>
                {(0 < roomTrophyList.length || 0 < room.star) && (
                  <Fragment>
                    <div className={css(styles.awards)}>
                      <Row
                        type="inline"
                        flexWrap={true}
                        gutter={0.5}
                        style={{ justifyContent: "flex-end" }}
                      >
                        {0 < roomTrophyList.length && (
                          <Fragment>
                            {roomTrophyList.map((trophy, index) => (
                              <Col key={index}>
                                <RoomTrophy trophy={trophy} />
                              </Col>
                            ))}
                          </Fragment>
                        )}
                        {(room?.metas?.ratings ?? []).some(
                          (rating) =>
                            HOTEL_RATING_TYPE_VALUE_STAR === rating.type
                        ) && (
                          <Col>
                            <RoomRankingStar
                              value={
                                room.metas.ratings.find(
                                  (rating) =>
                                    HOTEL_RATING_TYPE_VALUE_STAR === rating.type
                                ).value
                              }
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </Col>

        <Col colSpan={3}>
          <RoomHeader
            reference={room.reference}
            linkProps={linkProps}
            city={room.city}
            distance={0}
            name={room.name}
            registrationNumber={room.registrationNumber}
            to={to}
            type={room.type}
            zipcode={room.zipcode}
            flux={flux}
            context={context}
            onPress={onPress}
          />

          <Stack marginTop={0.5} />

          <Text aStyle={styles.about}>{room.infos}</Text>

          <Stack marginTop={0.5} />

          <div className={css(styles.feedback)}>
            <div>
              <RoomRankings
                room={room}
                color={room.bestColor}
                shouldHideStar={true}
                shouldSeparateMainRatings={false}
              />

              <Stack marginTop={0.5} />
            </div>

            {room.recentlyAdded ||
              (roomHasReviews({ room }) && (
                <div>
                  <Row type="inline" flexWrap={true} gutter={0.625}>
                    {room.recentlyAdded && (
                      <Col>
                        <RoomNewTag />
                      </Col>
                    )}

                    {roomHasReviews({ room }) && (
                      <Col>
                        <RoomRateAndReviews
                          rate={room.rate}
                          reviews={room.reviews}
                        />

                        <Stack marginTop={0.5} />
                      </Col>
                    )}
                  </Row>
                </div>
              ))}
          </div>

          <RoomMetaList
            aStyle={styles.roomMetaList}
            flux={"inline"}
            categoryList={room.categoryList}
            capacity={room.maxCapacity}
            beds={room.beds}
            chambers={room.chambers}
            arePetsAllowed={room.arePetsAllowed}
            surface={room.surface}
            hostTable={room.hostTable}
            handicapList={room.handicapList}
          />
        </Col>
      </Row>
    </BaseComponent>
  );
};

export default Inline;
