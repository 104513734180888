// dependencies
import React from "react";
import { useIntl } from "react-intl";

// components
import BaseComponent from "@cloudspire/legacy-resources/src/components/BaseComponent2";
import { Row, Col } from "@cloudspire/legacy-resources/src/components/Grid2";
import Text from "@cloudspire/legacy-resources/src/components/Text2";
import {
  IconCapacityO,
  IconChamberO,
  IconBedO,
  IconAnimalsO,
  IconAnimalnoO,
  IconRestaurantO,
  IconTourismeEtHandicap,
  IconSurfaceO,
} from "@cloudspire/legacy-svg-icon-library";

// constants
import { ROOM_CATEGORY_VALUE_CAMPING } from "@cloudspire/legacy-resources/src/constants/room";

type PropsType = {
  flux: "block" | "inline" | "detail";
  categoryList: Array<
    keyof typeof import("@cloudspire/legacy-resources/src/constants/room").ROOM_CATEGORIES
  >;
  capacity: number;
  beds: number;
  chambers: number;
  arePetsAllowed: boolean;
  surface: number;
  hostTable: number;
  handicapList: Array<string>;
  aStyle?;
};

const RoomMetaList: React.FunctionComponent<PropsType> = (props) => {
  const {
    categoryList,
    flux,
    beds,
    capacity,
    chambers,
    arePetsAllowed,
    surface,
    hostTable,
    handicapList,
    aStyle,
  } = props;

  const intl = useIntl();

  return (
    <BaseComponent aStyle={aStyle}>
      <Row
        type="inline"
        flexWrap={true}
        gutter={{
          vertical: 0.125,
          horizontal: 0.5,
        }}
      >
        {capacity > 0 && (
          <Col>
            <Text>
              <IconCapacityO />
              &nbsp;
              {intl.formatMessage(
                {
                  defaultMessage:
                    "{capacity, plural, one {# personne} other {# personnes}}",
                },
                { capacity }
              )}
            </Text>
          </Col>
        )}

        {["detail", "inline"].includes(flux) && surface > 0 && (
          <Col>
            <Text>
              <IconSurfaceO />
              &nbsp;
              {intl.formatMessage(
                {
                  defaultMessage: "{surface} m²",
                },
                { surface }
              )}
            </Text>
          </Col>
        )}

        {["block"].includes(flux) && chambers > 0 && (
          <Col>
            <Text>
              <IconChamberO />
              &nbsp;
              {intl.formatMessage(
                {
                  defaultMessage:
                    "{chambers, plural, one {{isCamping, select, yes {{chambers} emplacement} other {{chambers} chambre}}} other {{isCamping, select, yes {{chambers} emplacements} other {{chambers} chambres}}}}",
                },
                {
                  chambers,
                  isCamping: categoryList.includes(ROOM_CATEGORY_VALUE_CAMPING)
                    ? "yes"
                    : "no",
                }
              )}
            </Text>
          </Col>
        )}

        {beds > 0 && (
          <Col>
            <Text>
              <IconBedO />
              &nbsp;
              {intl.formatMessage(
                {
                  defaultMessage: "{beds, plural, one {# lit} other {# lits}}",
                },
                { beds }
              )}
            </Text>
          </Col>
        )}

        {arePetsAllowed ? (
          <Col>
            <Text>
              <IconAnimalsO />
              &nbsp;
              {intl.formatMessage({
                defaultMessage: "Animaux autorisés",
              })}
            </Text>
          </Col>
        ) : (
          <Col>
            <Text>
              <IconAnimalnoO />
              &nbsp;
              {intl.formatMessage({
                defaultMessage: "Animaux refusés",
              })}
            </Text>
          </Col>
        )}

        {["inline", "block"].includes(flux) && hostTable > 0 && (
          <Col>
            <Text>
              <IconRestaurantO />
              &nbsp;
              {intl.formatMessage({
                defaultMessage: "Table d'hôte",
              })}
            </Text>
          </Col>
        )}

        {["inline", "block"].includes(flux) && handicapList.length > 0 && (
          <Col>
            <Text>
              <IconTourismeEtHandicap />
              &nbsp;
              {intl.formatMessage({
                defaultMessage: "Tourisme & Handicap",
              })}
            </Text>
          </Col>
        )}
      </Row>
    </BaseComponent>
  );
};

export default RoomMetaList;
