// deps
import React from "react";
import { css, StyleSheet } from "aphrodite";

// libraries
import { generateUri } from "@cloudspire/legacy-shared/src/libraries";

// constants
import mediasRouter from "@cloudspire/legacy-shared/src/constants/router/mediasRouter";
import { HOTEL_RATING_STARS } from "@cloudspire/shared/constants/hotel/rating";

const styles = StyleSheet.create({
  star: {
    width: "4rem",
    height: "4rem",
  },
});

type PropsType = {
  value: keyof typeof import("@cloudspire/shared/constants/hotel/rating").HOTEL_RATING_STARS;
};

function RoomRankingsStar(props: PropsType) {
  const { value } = props;

  const starInfo = HOTEL_RATING_STARS?.[value];

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      className={css(styles.star)}
      src={generateUri({
        router: mediasRouter,
        name: "home",
        parameters: {
          path: starInfo.logoPath,
        },
      })}
      alt={starInfo.label}
      title={starInfo.label}
    />
  );
}

export default RoomRankingsStar;
