// dependencies
import React from "react";
import { useIntl } from "react-intl";
import { StyleSheet } from "aphrodite";

// components
import Tag from "@cloudspire/legacy-resources/src/components/Tag";

type PropsType = {
  aStyle?;
};

const styles = StyleSheet.create({
  tag: {
    backgroundColor: "#f45d5c",
    borderColor: "#f45d5c",
    borderWidth: "0.0625rem",
    borderStyle: "solid",
    color: "#ffffff",
    fontSize: "0.875rem",
    textTransform: "capitalize",
  },
});

const RoomNewTag: React.FunctionComponent<PropsType> = (props) => {
  const { aStyle } = props;

  const intl = useIntl();

  return (
    <Tag aStyle={[styles.tag, aStyle]}>
      {intl.formatMessage({
        defaultMessage: "nouveauté",
      })}
    </Tag>
  );
};

export default RoomNewTag;
